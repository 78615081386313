<template>
  <div class="w-full h-full pt-5">
    <loader v-if="isLoading" size="xxs" :loader-image="false" />
    <template v-else>
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <c-backbutton label="Back" @onClick="$router.back()" variant="secondary" />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-3">Compensation</h1>
          <c-breadcrumb :items="breadcrumbs" />
        </div>
      </template>
      <template v-if="hasModuleAccess">
        <div class="container px-3">
          <template v-if="reviews">
            <div class="py-2 my-5">
              <div class="flex justify-start items-center gap-5">
                <h2 class="font-bold text-lg leading-5 text-darkPurple">
                  Detailed Report
                </h2>
                <c-card class="px-5 py-5 w-60 min-w-0">
                  <div class="border-l border-r border-romanSilver px-5 flex justify-start items-center gap-2">
                    <c-card class="flex p-2">
                      <Icon icon-name="icon-financial" size="s" />
                    </c-card>
                    <div class="flex flex-col justify-start items-start gap-0">
                      <p class="text-xs text-jet uppercase font-semibold">Financial Year</p>
                      <p class="font-bold text-lg text-blueCrayola">
                        {{ reviews.year ? reviews.year : 'N/A' }}
                      </p>
                    </div>
                  </div>
                </c-card>
                <c-card class="px-5 py-5 min-w-0">
                  <div class="border-l border-r border-romanSilver px-5 flex justify-start items-center gap-2">
                    <c-card class="flex p-2">
                      <Icon icon-name="icon-review-timeline" size="s" />
                    </c-card>
                    <div class="flex flex-col justify-start items-start gap-0">
                      <p class="text-xs text-jet uppercase font-semibold">Review Cycle</p>
                      <p class="font-bold text-lg text-mediumSeaGreen whitespace-nowrap">
                        {{ reviews.cycle ? reviews.cycle.cycleName : 'N/A' }}
                      </p>
                    </div>
                  </div>
                </c-card>
              </div>
              <c-card class="px-5 pb-6 my-3">
                <CardFooter
                  reloadcard
                  @reload="onGetQuery"
                  @actionModal="() => {}"
                  @sortType="onGetQuery({ sort: $event })"
                  @searchResult="onGetQuery({ search: $event })"
                >
                  <template v-slot:leftItems>
                    <div class="flex items-center gap-5">
                      <div class="flex gap-2">
                        <template>
                          <c-button
                            label="View Timeline"
                            variant="secondary button-class btn-border"
                            @onClick="priviledgedActions(reviews, 'viewTimelines')"
                          />
                          <ReviewTimelines ref="ref_timelines" />
                        </template>
                        <c-button
                          label="Submit"
                          variant="primary btn-bg button-class"
                          style="width:120px"
                          @onClick="onSubmitDetailedReport"
                        />
                      </div>
                      <div class="flex justify-center items-center rounded-md shadow p-3 cursor-pointer">
                        <Icon size="xs" icon-name="icon-printer" />
                      </div>
                    </div>
                  </template>
                </CardFooter>
              </c-card>
            </div>
          </template>
          <template>
            <template v-if="employeeReviews.length">
              <div>
                <c-table
                  :headers="headers"
                  :items="employeeReviews"
                  :has-number="true"
                  :has-checkbox="true"
                  class="w-full"
                  :loading="isFetching"
                  @rowSelected="selectedItems = $event"
                  :pagination-list="pagination"
                  page-sync
                  @page="onGetQuery({ page: $event })"
                  @itemsPerPage="onGetQuery({ perPage: $event })"
                >
                  <template v-slot:item="{ item }">
                    <div v-if="item.employee" class="font-normal text-sm">
                      <div class="flex flex-col justify-start items-start">
                        <span class="text-darkPurple">
                          {{ item.data.employee.fname }}  {{ item.data.employee.lname }}
                        </span>
                        <span class="text-romanSilver">
                          {{ item.data.employee.designation }}
                        </span>
                      </div>
                    </div>
                    <div v-if="item.serviceYears" class="font-normal text-sm">
                      <span class="text-darkPurple" v-if="item.data.serviceYears">
                        {{ item.data.serviceYears }}
                      </span>
                      <span v-else> - </span>
                    </div>
                    <div v-if="item.rangeMidPoint" class="font-normal text-sm">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.rangeMidPoint) }}
                      </span>
                    </div>
                    <div v-if="item.currentGrossPay" class="font-normal text-sm">
                        <span class="text-darkPurple">
                          {{ convertToCurrency(item.data.currentGrossPay) }}
                        </span>
                    </div>
                    <div v-if="item.change" class="font-normal text-sm">
                      <span class="text-darkPurple">{{ item.data.change }}%</span>
                    </div>
                    <div v-if="item.increase" class="font-normal text-sm">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.increase) }}
                      </span>
                    </div>
                    <div v-if="item.newGrossPay" class="font-normal text-sm">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.newGrossPay) }}
                      </span>
                    </div>
                    <div v-if="item.userId">
                      <c-menu left @click.stop="">
                        <template v-slot:title>
                          <div><Icon icon-name="more_icon" size="xs" /></div>
                        </template>
                        <template>
                          <div class="flex flex-col w-60 h-18 p-2 justify-start items-start">
                            <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                              <div @click="onToggleEmployeeActions('discretionary_adjustment', item.data)">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="edit" class-name="text-blueCrayola" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    Discretionary Adjustment
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div @click="onToggleEmployeeActions('promotion', item.data)" class="container">
                              <div class="hover:bg-ghostWhite w-full rounded-md cursor-pointer">
                                <div class="flex justify-start items-center p-2 gap-2">
                                  <Icon icon-name="file_todo" class-name="text-carrotOrange" size="xs" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal">
                                    Promote
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div @click="onToggleEmployeeActions('lump_sum_award', item.data)" class="container">
                              <div class="hover:bg-ghostWhite w-full rounded-md">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="icon-timer" class-name="text-flame -ml-1" size="s" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal -ml-1">
                                    Award Lump Sum
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div @click="onToggleEmployeeActions('review_proposal', item.data)" class="container">
                              <div class="hover:bg-ghostWhite w-full rounded-md">
                                <div class="flex justify-start items-center p-2 gap-2 cursor-pointer">
                                  <Icon icon-name="icon-timer" class-name="text-flame -ml-1" size="s" />
                                  <span class="text-darkPurple text-sm leading-5 font-normal -ml-1">
                                    Review Proposal
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </c-menu>
                    </div>
                  </template>
                </c-table>
              </div>
            </template>
            <template v-else>
              <div class="w-full flex flex-col justify-center items-center gap-5 px-10 pb-20 w-full">
                <Icon size="" class-name="w-80 h-80" icon-name="comreview_illustration" />
                <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
                  Detailed Report not available for this Review. You should start adding employees to the list.
                </p>
              </div>
            </template>
          </template>
          <template>
            <DiscretionaryAdjustment
              ref="discretionary_adjustment"
              review-type="compensation_review"
              @submit="hasContinueAction = false, onGetQuery()"
              @close="hasContinueAction = false, onGetQuery()"
            />
            <AwardLumpSum
              ref="lump_sum_award"
              @submit="hasContinueAction = false, onGetQuery()"
              @close="hasContinueAction = false, onGetQuery()"
            />
            <PromoteEmployee
              ref="promote_employee"
              @submit="hasContinueAction = false, onGetQuery()"
              @close="hasContinueAction = false, onGetQuery()"
            />
            <ReviewProposal
              ref="review_proposal"
              @submit="hasContinueAction = false, onGetQuery()"
            />
          </template>
          <template>
            <div v-if="isOpenFilter" class="filter_table">
              <FilterTable
                v-if="isOpenFilter"
                :filter-data="filterOptions"
                @close="isOpenFilter = false"
                @submit="isOpenFilter = false"
              />
            </div>
          </template>
          <template>
            <Modal v-if="hasAdjustmentAction">
              <c-card class="p-5 flex flex-col">
                <div class="border-b border-romanSilver">
                  <p class="text-jet font-bold text-base">Action Required!</p>
                </div>
                <div class="flex flex-col justify-start gap-2">
                  <div class="relative my-5">
                    <Alert
                      message="This employee already has a pending adjustment"
                      variant="primary"
                      :time="2"
                      class="alert-style"
                    />
                    <div class="flex flex-col justify-start items-start gap-1">
                      <p class="font-bold text-base text-jet">Previous Adjustment:
                        <span class="font-semibold capitalize">{{ previousAdjustment }}</span>
                      </p>
                      <p class="font-bold text-base text-jet">Current Adjustment:
                        <span class="font-semibold capitalize">{{ currentAdjustment }}</span>
                      </p>
                    </div>
                  </div>
                  <p>Do you wish to continue?</p>
                  <div class="flex justify-end items-center gap-2">
                    <c-button
                      class="button-class button-class-secondary btn-border"
                      label="Cancel"
                      @onClick="() => {
                        hasAdjustmentAction = false;
                        hasContinueAction = false
                      }"
                    />
                    <c-button
                      class="button-class btn-bg button-class-pimary"
                      label="Continue"
                      @onClick="() => {
                        hasContinueAction = true;
                        onToggleEmployeeActions(currentActionType, currentPayload)
                      }"
                    />
                  </div>
                </div>
              </c-card>
            </Modal>
          </template>
        </div>
      </template>
      <div v-else>
        <ErrorComponent />
      </div>
    </template>
  </div>
</template>

<script>
  import * as _ from "lodash"
  import Alert from "@scelloo/cloudenly-ui/src/components/alert";
  import CBreadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
  import CBackbutton from "@scelloo/cloudenly-ui/src/components/back-button";
  import CButton from "@scelloo/cloudenly-ui/src/components/button";
  import CTable from "@scelloo/cloudenly-ui/src/components/table";
  import CCard from "@scelloo/cloudenly-ui/src/components/card";
  import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
  // import CModal from "@scelloo/cloudenly-ui/src/components/modal";
  import CardFooter from "@/components/CardFooter";
  import Icon from "@/components/Icon";
  import Modal from "@/components/Modal";

  import FilterTable from "@/components/FilterTable";
  import ErrorComponent from "@/modules/Admin/error403"

  export default {
    components: {
      Alert,
      CBreadcrumb,
      CBackbutton,
      CTable,
      CMenu,
      FilterTable,
      CCard,
      Icon,
      CButton,
      CardFooter,
      Modal,
      ErrorComponent,
      ReviewProposal: () => import('../Modals/ReviewProposal'),
      AwardLumpSum: () => import('../Modals/AwardLumpSum'),
      PromoteEmployee: () => import('../Modals/PromoteEmployee'),
      ReviewTimelines: () => import('../Modals/ReviewTimelines'),
      DiscretionaryAdjustment: () => import('../Modals/DiscretionaryAdjustment'),
    },
    data(){
      return {
        reviews: {},
        reviewId: '',
        isLoading: true,
        isOpenFilter: false,
        hasModuleAccess: false,
        hasContinueAction: false,
        hasAdjustmentAction: false,
        previousAdjustment: '',
        currentAdjustment: '',
        currentActionType: '',
        currentPayload: {},
        isFetching: true,
        filterOptions: [],
        rankingOptions: [],
        paygradeOptions: [],
        selectedItems: [],
        employeeReviews: [],
        levelOptions: [],
        designationOptions: [],
        functionOptions: [],
        locationOptions: [],
        compareBenchmark: 'employee',
        breadcrumbs: [
          { disabled: false, text: "Compensation", href: "compensation", id: "Compensation" },
          { disabled: false, text: "Compensation Review", href: "CompensationReview", id: "CompensationReview" },
          { disabled: false, text: "Detailed Report", href: "CompensationReviewDetailedReport", id: "CompensationReviewDetailedReport" }
        ],
        headers: [
          { title: "employee", value: "employee" },
          { title: "length of service", value: "serviceYears" },
          { title: "range mid-point", value: "rangeMidPoint" },
          { title: "current gross pay", value: "currentGrossPay" },
          { title: "change (%)", value: "change" },
          { title: "change (ngn)", value: "increase" },
          { title: "new gross pay", value: "newGrossPay" },
          { title: "", value: "userId", image: true },
        ],
        pagination: { page: 1, lastPage: 1, total: 1, from: 1, to: 1 },
      }
    },
    methods: {
      onToggleEmployeeActions(actionType, payload) {
        this.currentActionType = actionType
        this.currentPayload = payload
        if(payload.paygradeId) {
          if (!payload.adjustment || this.hasContinueAction || actionType === 'review_proposal') {
            this.hasAdjustmentAction = false
            if (actionType === 'discretionary_adjustment') {
              this.$refs.discretionary_adjustment.toggle({
                userId: payload.userId,
                paygradeId: payload.paygradeId,
                reviewId: this.reviewId
              })
              this.hasContinueAction = false
            } else if (actionType === 'promotion'){
              this.$refs.promote_employee.toggle({
                userId: payload.userId,
                paygradeId: payload.paygradeId,
                reviewId: this.reviewId
              })
              this.hasContinueAction = false
            } else if (actionType === 'lump_sum_award'){
              this.$refs.lump_sum_award.toggle({
                userId: payload.userId,
                paygradeId: payload.paygradeId,
                reviewId: this.reviewId
              })
              this.hasContinueAction = false
            } else if (actionType === 'review_proposal'){
              this.$refs.review_proposal.toggle({
                userId: payload.userId,
                paygradeId: payload.paygradeId,
                reviewId: this.reviewId,
                recommendation: payload.recommendation
              })
              this.hasContinueAction = false
            }
          } else {
            this.previousAdjustment = payload.adjustment?.adjustmentType?.split('_')?.join(' ')
            this.currentAdjustment  = actionType?.split('_')?.join(' ')
            this.hasAdjustmentAction = true;
          }
        } else {
          const message = "No paygrade is set for this employee!"
          this.$toasted.info(message, { duration: 3000 })
        }
      },
      async onGetQuery(queryParams){
        try {
          await this.$handlePrivilege('reviewHRR', 'viewAndUpdateDetailedReport')
          if(queryParams !== undefined){
            this.queryParams = {...this.queryParams, ...queryParams}
            this.getCompensationReviewDetailsById({
              queryParams: this.queryParams,
              reviewId: this.reviewId
            })
          } else {
            this.queryParams = {}
            this.getCompensationReviewDetailsById({
              queryParams: this.queryParams,
              reviewId: this.reviewId
            })
          }
        } catch (error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      async priviledgedActions(payload, setAction){
        try {
          if(setAction === 'viewTimelines'){
            await this.$handlePrivilege('reviewHRR', 'viewTimelines')
            this.$refs.ref_timelines.toggle(payload)
          }
        } catch(error) {
          const message = 'You do not have permission to perform this task'
          this.$toasted.error(message, { duration: 3000 })
        }
      },
      onFilter(){
        this.filterOptions = [
          { header: 'Location(s)', optionsBox: this.locationOptions },
          { header: 'Function(s)', optionsBox: this.functionOptions },
          { header: 'Level(s)', optionsBox: this.levelOptions },
          { header: 'Designation(s)', optionsBox: this.designationOptions },
          { header: 'Pay Grade', optionsBox: this.paygradeOptions },
          { header: 'Performance Score', optionsBox: this.rankingOptions },
          { header: 'Gender', optionsBox: ['Male', 'Female'] },
          { header: 'Manager', optionsBox: this.managerOptions },
        ]
        this.isOpenFilter = true
      },
      onSubmitDetailedReport(){
        if(_.isEmpty(this.selectedItems)){
          const message = "Please select at least one employee to continue!"
          return this.$toasted.info(message, { duration: 3000 })
        }

        const selectedItems = this.selectedItems.map(item => ({
          adjustmentId: item.adjustment ? item.adjustment.adjustmentId : null,
          userId: item.userId ? item.userId : null,
        }))?.filter(item => item.userId && item.adjustmentId);

        const payload = {
          orgId: this.$orgId,
          reviewId: this.reviewId,
          employees: selectedItems,
        }

        this.isFetching = true
        return this.$_postCompensationReviewForApproval(payload).then(({ data }) => {
          this.$toasted.success(data.message, {duration: 3000})
          this.onGetQuery()
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.onGetQuery()
        })
      },
      async getCompensationReviewDetailsById(payload){
        this.isFetching = true
        await this.$_getCompensationReviewDetails(payload).then(({ data }) => {
          this.employeeReviews = data.data?.map((item) => ({
            ...item, ...item.employee, paygradeId: item.paygrade?.id
          }))
          this.paygradeOptions = data.data?.map(i => i.paygrade).filter(
            i => i).map(i => ({ ...i, name: i.payGradeId, value: i.id })
          )
          this.pagination = data.meta
          this.isFetching = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isFetching = false
        })
      },
      async findCompensationReviewById(reviewId){
        await this.$_findCompensationReviewById(reviewId)
        .then(({ data }) => { this.reviews = data.data })
      },
      async getDesignation() {
        await this.$_getDesignations('').then(({ data }) => {
          this.designationOptions = data.designations.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getLevels() {
        await this.$_getLevels().then(({ data }) => {
          this.levelOptions = data.levels.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getFunctions() {
        await this.$_getFunction().then(({ data }) => {
          this.functionOptions = data.functions.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getLocations() {
        this.$_getHumanarLocations().then(({ data }) => {
          this.locationOptions = data.outlets.map(item => ({
            value: item.id, ...item
          }))
        })
      },
      async getOrgPerfomanceRankings(){
        await this.$_getRanking().then(({ data }) => {
          this.rankingOptions = data.rankings.map((item) => ({
            ...item, name: item.flag.name, value: item.flagId
          }))
        })
      },
      async getHumanarEmployees(){
        this.$_getAllEmployeesWithQueries('').then(({ data }) => {
          this.managerOptions = data.data.employees.filter(
            item => item.userDesignation?.isSupervisory).map(item => (
              {...item, name: `${item.fname} ${item.lname}`, value: item.userId }
            )
          )
        })
      },
      async bootstrapModule(){
        this.reviewId = this.$route.params.id
        await this.findCompensationReviewById(this.reviewId)
        await this.getCompensationReviewDetailsById({
          queryParams: this.queryParams,
          reviewId: this.reviewId
        })
        this.getDesignation()
        this.getLevels()
        this.getFunctions()
        this.getLocations()
        this.getOrgPerfomanceRankings()
        this.getHumanarEmployees()
      }
    },
    async created(){
      this.isLoading = true
      try {
        await this.$handlePrivilege('reviewHRR', 'viewAndUpdateDetailedReport')
        this.hasModuleAccess = true
        this.bootstrapModule()
        this.isLoading = false
      } catch (error) {
        this.hasModuleAccess = false
        this.isLoading = false
      }
    },
  }
</script>

<style scoped lang="scss">
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 40px !important;
 }
 .button_class {
    padding: 10px 40px !important;
    font-weight: 600;
    display: flex;
    justify-self: end;
  }
  .button-class-pimary {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .button-class-secondary {
    color: #321c3b !important;
    background: white !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    padding: 10px 40px !important;
  }
  .alert-style {
    position: relative;
    right: 0; top: -5px;
    box-shadow: none;
    background: #FFFFFF;
    color: #E99323;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid rgb(254 202 202);
  }
</style>
<style scoped>
.filter_table div span .cardClass {
  display: flex !important;
  height: 95vh !important;

}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }

 .btn-border{
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
